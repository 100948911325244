import React from "react";
import Logo from '../../images/db-logo-white.png'

const Header = () => {
  return(
    <header className="flex items-center justify-center p-4 bg-black">
      <img 
      src={Logo} 
      alt="DB Method Logo"
      loading="eager"
      width={280}
      height={38}
      />
    </header>
    
  )
}

export default Header;