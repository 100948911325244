import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import firebase from 'gatsby-plugin-firebase';

const items =[
  {
    'heading': 'Is this a total body workout?',
    'content': 'Our method works your entire lower body (glutes, quads, thighs, hamstrings, hips, and calves) and core. You can also use the machine to isolate just your chest, arms and abs. We offer a full body workout for maximum results in minimum time.'
  },
  {
    'heading': 'How is it on the knees and back?',
    'content': 'The way the machine aligns the body takes pressure off the knees and back so it’s easy on the joints. If you have pre-existing conditions you should always consult your doctor to determine what is good for you specifically.'
  },
  {
    'heading': 'Will this fit in my apartment or home?',
    'content': 'Yes, the machine folds down easily for storage and even has wheels so you can roll it under a bed or into a closet. You can find the machine dimensions in the "Details" section right above.'
  },
  {
    'heading': 'Do you all have coupons/discount codes?',
    'content': 'We don’t offer discounts or have sales. We manufacture our products and sell directly to you with no middle man so that we can offer the lowest possible price, every single day.'
  },
  {
    'heading': 'Accessory suggestions?',
    'content': 'We have designed kits to fit your lifestyle and workout goals. Our Essentials Kit is a best-seller because it provides everything you need to start and progress your journey of strength. Our Deluxe Kit takes The DB Method to the next level so you can tone, tighten and lift with every type of workout in our collection.'
  }
]

const FAQ = () => {
  return(
    <div className="max-w-screen-2xl mx-auto px-5 py-10 md:p-16 faq__module">
      <h3 className="text-3.5xl md:text-5xl text-center md:text-left font-gtbold uppercase tracking-wide mb-5">Frequently Asked Questions</h3>
      <Accordion allowZeroExpanded preExpanded={['0']} className="border-b border-gray-300" onChange={(e) => firebase.analytics().logEvent('faq_accordian')}>
        {items.map((item, index) => (
            <AccordionItem uuid={String(index)} key={index} className="border-t border-gray-300 py-4">
                <AccordionItemHeading>
                    <AccordionItemButton className="text-lg flex justify-between">
                        {item.heading}
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className="text-sm mt-3">
                  {item.content}
                </AccordionItemPanel>
            </AccordionItem>
        ))}
    </Accordion>
    </div>
  )
}

export default FAQ;