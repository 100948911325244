import * as React from "react"
import Layout from '../components/Layout/Layout';
import Hero from '../components/HeroModules/Hero';
import Users from '../components/UsersModule/UsersSingle';
import Alternate from '../components/Sections/Alternate';
import Appmodule from '../components/Sections/AppModule';
import PressLogos from '../components/Sections/PressLogos';
import PressLogosMobile from '../components/Sections/PressLogosMobile';
import TheDetails from '../components/Sections/TheDetails';
import ProductBuyBox from '../components/Sections/ProductBuyBox';
import Faq from '../components/Sections/Faq';
import Package from '../components/Packages/Package';
import Videos from '../components/Sections/Videos';
import { PageContext } from '../Contexts/PageContext';
import firebase from 'gatsby-plugin-firebase';

// markup
const IndexPage = () => {  
  
  const [showPackage, setShowPackage] = React.useState(false); 

  const handlePackage = (e) => {
    document.body.style.overflow = 'hidden';
    setShowPackage(true);
    // Tracking package modal open
    firebase.analytics().logEvent('package_modal_open', {
      section: e.target.id
    });
  }
  const closePackage = (e) => {
    document.body.style.overflow = 'visible';
    setShowPackage(false);
    // Tracking package modal open
    firebase.analytics().logEvent('package_modal_close');
  }

  return (    
    <Layout title="Our version of the squat targets erectile dysfunction">
      <PageContext.Provider
        value={{ handlePackage, closePackage }}
      >
        <Hero /> 
        <PressLogos />
        <PressLogosMobile />
        <Alternate />    
        <div className="italic text-xxs text-center disclaimer mb-8 md:mb-14 px-5"> 
        *References available on request. Always consult your healthcare professional before starting a new fitness routine, especially if you have pre-existing conditions.   
        </div>    
        <Appmodule />    
        <Videos />    
        <Users />        
        <TheDetails />
        <ProductBuyBox />
        <Faq />
        {showPackage &&
          <Package />
        }        
      </PageContext.Provider>
    </Layout>
  )
}

export default IndexPage;