import React from 'react';
import HealthLogo from '../../images/press-logos/nw-health-white.svg';
import Muscle from '../../images/press-logos/nw-musclefiitness-white.svg';
import Forbes from '../../images/press-logos/nw-forbes-white.svg';
import Money from '../../images/press-logos/nw-money-white.svg';

const PressLogos = () => {
  return(
    <div className="hidden md:flex justify-center items-center nowrap px-5 py-16 bg-gray press__logos">
      <img
        src={Muscle}
        alt="Muscle&amp;Fitness"
        style={{ marginRight: '70px', filter: 'brightness(0)', height: '35px'}}
        height={27}
        loading="eager"
      />
      <img
        src={HealthLogo}
        alt="Health"
        style={{ marginRight: '70px', filter: 'brightness(0)', height: '35px'}}
        height={27}
        loading="eager"
      />
      <img
        src={Forbes}
        alt="Forbes"
        style={{ marginRight: '70px', filter: 'brightness(0)', height: '35px'}}
        height={30}
        loading="eager"
      />
      <img
        src={Money}
        alt="Money"
        style={{ filter: 'brightness(0)', height: '35px' }}
        height={37}
        loading="eager"
      />
    </div>
  )
}

export default PressLogos;