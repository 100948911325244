import React from 'react';
import GridVideo from '../../videos/lp-vid-1.mp4';
import { PageContext } from '../../Contexts/PageContext';

function Alternate(){
  const { handlePackage } = React.useContext(PageContext);

  return(
    <div className="grid__layout pt-10 md:py-14">
      <div className="mx-auto max-w-screen-2xl flex flex-wrap items-center px-0 md:px-16 ">
        <div className="w-full md:w-2/4 order-2 mt-8 md:mt-0">
        <video className="hidden md:block md:rounded-lg" autoPlay muted playsInline loop>
          <source src={GridVideo} type="video/mp4" />
        </video>        
        </div>
        <div className="w-full md:w-2/4 md:pl-20 px-5 md:px-0 md:order-2">
          <h3 className="font-gtbold text-3.5xl md:text-5xl mb-8 md:mb-16 uppercase">THE NATURAL WAY TO IMPROVE YOUR SEXUAL HEALTH</h3>
          <p className="text-xl md:text-2xl mb-1 md:mb-5">Boost Testosterone</p>
          <span className="block text-sm md:text-base mb-8 md:mb-10">High-intensity squats have been proven to boost testosterone levels and improve blood flow to the pelvic region.  The more blood flow near the penis, the easier it is to gain an erection.</span>
          <p className="text-xl md:text-2xl mb-1 md:mb-5">Reclaim Your Youth</p>
          <span className="block text-sm md:text-base mb-8 md:mb-10">Our elite trainers have done the hard work and created programs to motivate and guide you every day so you can focus on reclaiming your vitality.</span>
          <p className="text-xl md:text-2xl mb-1 md:mb-5">Based on Science</p>
          <span className="block text-sm md:text-base mb-4 md:mb-6">Studies have found that pelvic exercises helped 40 percent of men with ED regain normal erectile function. They also helped an additional 33.5 percent significantly improve erectile function.</span>         
          <button id="train-cta" className="text-base font-medium py-2 px-8 rounded-full bg-primary hover:bg-primary-hover font-gtmedium" onClick={handlePackage}>Buy the Machine</button>          
        </div>
      </div>
    </div>
  )
}

export default Alternate;