import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const TheDetails = () => {
  return(
    <div className="the__details">
      <div className="flex flex-wrap items-center max-w-screen-2xl mx-auto pb-8 px-0 md:p-16">
        <div className="w-full md:w-2/4 md:pr-12 order-2 px-5 md:px-0">
          <h4 className="font-gtbold text-3.5xl md:text-5xl text-center md:text-left uppercase mb-6">The Details</h4>
          <div className="specs text-sm md:text-base">
            <p className="flex justify-between mb-3">
              <span className="w-2/4" style={{ color: '#6f6f6f'}}>Dimensions</span>
              <span className="w-2/4">45 1/2" x 20" x 39 1/2"</span>
            </p>
            <p className="flex justify-between mb-3">
              <span className="w-2/4" style={{ color: '#6f6f6f'}}>Extendable Seat for Heights</span>
              <span className="w-2/4">5'0"-6'3"</span>
            </p>
            <p className="flex justify-between mb-3">
              <span className="w-2/4" style={{ color: '#6f6f6f'}}>Folded Height</span>
              <span className="w-2/4">7’’ at highest point</span>
            </p>
            <p className="flex justify-between mb-3">
              <span className="w-2/4" style={{ color: '#6f6f6f'}}>Maximum User Weight</span>
              <span className="w-2/4">250 lbs</span>
            </p>
            <p className="flex justify-between mb-3">
              <span className="w-2/4" style={{ color: '#6f6f6f'}}>Machine Weight </span>
              <span className="w-2/4">29 1/2 lbs</span>
            </p>
            <p className="flex justify-between mb-3">
              <span className="w-2/4" style={{ color: '#6f6f6f'}}>Material</span>
              <span className="w-2/4">Steel</span>
            </p>
            <p className="flex justify-between mb-3">
              <span className="w-2/4" style={{ color: '#6f6f6f'}}>Assembly</span>
              <span className="w-2/4">No-tools Assembly (parts click into place)</span>
            </p>
            <p className="flex justify-between mb-3">
              <span className="w-2/4" style={{ color: '#6f6f6f'}}>Warranty</span>
              <span className="w-2/4">1-year Limited Manufacturer's Warranty</span>
            </p>
          </div>
        </div>
        <div className="block w-full md:w-2/4 md:pl-12 mb-8 md:mb-0 md:order-2">
          <div className="hidden md:block">
            <StaticImage
              src="../../images/details-image.jpg"
              alt="Model Shoot Though Handrails"
              width={670}
              className="rounded-lg"
            />
          </div>
          <div className="block md:hidden">
            <StaticImage
              src="../../images/hero/lp-hero-mobile.jpg"
              alt="Hero Image Mobile"
              className=" hero-mobile"
              loading="eager"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TheDetails;