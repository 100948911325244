import React from 'react';
import HealthLogo from '../../images/press-logos/nw-health-white.svg';
import Muscle from '../../images/press-logos/nw-musclefiitness-white.svg';
import Forbes from '../../images/press-logos/nw-forbes-white.svg';
import Money from '../../images/press-logos/nw-money-white.svg';

const PressLogosMobile = () => {
  return(
    <>
    <div className="flex md:hidden justify-between items-center flex-wrap px-5 pt-8 bg-gray press__logos--mobile mt-10 md:mt-0">
      <img
        src={Muscle}
        alt="Muscle"
        style={{ filter: 'brightness(0)', height: '20px'}}
        className="mb-8"
        height={20}
      />
      <img
        src={Forbes}
        alt="Forbes"
        style={{ filter: 'brightness(0)', height: '20px'}}
        className="mb-8"
        height={20}
      />     
      <img
        src={Money}
        alt="Money"
        style={{ filter: 'brightness(0)', height: '20px' }}
        className="mb-8"
        height={20}
      />
      <img
        src={HealthLogo}
        alt="Health"
        style={{ filter: 'brightness(0)', height: '20px'}}
        className="mb-8"
        height={20}
      />      
    </div>
    </>
  )
}

export default PressLogosMobile;