import React, {useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

function Videos(){

  const [activeVideo, setActiveVideo ] = useState({
    status: false,
    videoId: ''
  })
  
  return(
    <div className="videos__layout py-10 md:py-14 text-white bg-black-1c">      
      <div className="mx-auto max-w-screen-2xl px-0 md:px-16 ">
        <div class="font-gtbold text-3.5xl md:text-5xl mb-8 uppercase text-center">DISCOVER MORE</div>
        <div className="block md:flex flex-wrap items-start whitespace-nowrap md:whitespace-normal overflow-x-auto ml-5 md:ml-0">
          <div className="w-11/12 md:w-1/3 px-0 pr-4 md:px-2 cursor-pointer inline-block align-top whitespace-normal"
            onClick={() => setActiveVideo({ status: true, videoId: '569584835'})}
          >
            <div className="image-wrapper">
              <StaticImage 
                src="../../images/videos/video-andrew.jpg"
                loading="lazy"
                alt="Lift Better Video"
                className="flex"
              />
            </div>
            <div className="text text-xl mt-6">LIFT BETTER</div>
            <div className="text mt-2">Coach Andrew explains how the machine supports his gym routine.</div>
          </div>
          <div className="w-11/12 md:w-1/3 px-0 pr-4 md:px-2 cursor-pointer inline-block align-top whitespace-normal"
            onClick={() => setActiveVideo({ status: true, videoId: '569584939'})}
          >
            <div className="image-wrapper">
              <StaticImage 
                src="../../images/videos/video-eddie.jpg"
                loading="lazy"
                alt="Run Better Video"
                className="flex"
              />
            </div>
            <div className="text text-xl mt-6">RUNNING FIT</div>
            <div className="text mt-2">Improve running performance with this workout.</div>
          </div>
          <div className="w-11/12 md:w-1/3 px-0 pr-4 md:px-2 cursor-pointer inline-block align-top whitespace-normal"
            onClick={() => setActiveVideo({ status: true, videoId: '569584736'})}
          >
            <div className="image-wrapper">
              <StaticImage 
                src="../../images/videos/video-group.jpg"
                loading="lazy"
                alt="Golf Better Video"
                className="flex"
              />
            </div>
            <div className="text text-xl mt-6">GOLF PRO</div>
            <div className="text mt-2">Work on a stronger golf drive by getting stronger glutes.</div>
          </div>
        </div>
      </div>
      {activeVideo.status && activeVideo.videoId !== '' &&
        <div className="fixed z-10 top-0 left-0 w-full h-full flex items-center justify-center">
          <span className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-80 md:bg-opacity-60" onClick={() => setActiveVideo({ status: false, videoId: ''})}></span>
          <span className="absolute top-5 right-5 cursor-pointer" onClick={() => setActiveVideo({ status: false, videoId: ''})}><svg class="" xmlns="http://www.w3.org/2000/svg" width="18.663" height="18.663" viewBox="0 0 18.663 18.663"><defs></defs><g transform="translate(-272.437 -7.01)"><g transform="translate(273.145 7.717)"><path class="a" style={{ fill:"none", stroke:"#fff","strokeWidth": "2px" }} d="M-63.855-5421.283l17.249,17.249" transform="translate(63.855 5421.283)"></path><path class="a" style={{ fill:"none", stroke:"#fff","strokeWidth": "2px" }} d="M-46.606-5421.283l-17.249,17.249" transform="translate(63.855 5421.283)"></path></g></g></svg></span>
          <div className="md:rounded-lg md:overflow-hidden md:bg-black-1c w-11/12 max-w-4xl relative h-5/6" style={{ position:"relative"}}><iframe src={`https://player.vimeo.com/video/${activeVideo.videoId}?autoplay=1&loop=1&muted=0`} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen style={{ position:"absolute",top:"0",left:"0",width:"100%",height:"100%"}} title="Video Playback"></iframe></div>
      </div>
      }      
    </div>
  )
}

export default Videos;