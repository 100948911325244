import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { PageContext } from '../../Contexts/PageContext';
import GridVideo from '../../videos/lp-vid-1.mp4';

function Hero(){
  const { handlePackage } = React.useContext(PageContext);

  return (
    <div className="relative flex flex-col md:flex-row hero-module">
      <div className="hidden md:flex">
      <StaticImage
        src="../../images/hero/lp-hero.jpg"
        alt="Hero Image"
        loading="eager"
      />
      </div>
      <div className="flex md:hidden">
        {/* <StaticImage
          src="../../images/hero/lp-hero-mobile.jpg"
          alt="Hero Image Mobile"
          className=" hero-mobile"
          loading="eager"
        /> */}
        <video className="md:rounded-lg" autoPlay muted playsInline loop>
          <source src={GridVideo} type="video/mp4" />
        </video> 
      </div>
      <div className="static md:absolute md:w-1/2 md:max-w-xl top-0 left-0 flex items-center md:items-start md:justify-center flex-col h-full pt-5 px-4 md:px-0 md:pt-0 md:ml-16 content">
        <h1 className="text-center md:text-left top-0 left-0 p-0 md:p-0 mb-5 md:mb-11 text-4xl md:text-8xl font-bold font-gtbold uppercase">BOOST<br className="hidden md:block" /> YOUR<br /> PERFORMANCE</h1>
        <div className="flex w-full md:w-auto justify-center md:justify-start md:items-start yotpo-reviews">
          <div className="flex">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5"><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5"><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5"><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5"><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5"><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
          </div>
          <span className="text-sm ml-2.5">(2770 Reviews)</span>
        </div>
        <div className="text mt-1 md:mt-4 text-md md:text-xl text-center md:text-left w-full md:w-auto">
        Our version of the squat targets erectile dysfunction.          
        </div>
        <div className="hidden md:flex flex-col md:flex-row items-center mt-4 md:mt-16 mb-2 md:mb-5 font-gtbold price-box w-56 mx-auto md:w-full">
          <h2 className="text-xl md:text-base font-bold md:pr-6 md:mr-6 md:border-r md:border-black">$229</h2>
          <div className="text-base font-bold afterpay text-center md:text-left mt-1.5 md:mt-0">
            <afterpay-placement 
              data-locale="en_US"
              data-currency="USD" 
              data-amount= "$229"
              style={{ margin: 0}}
            >
            </afterpay-placement>
          </div>
        </div>
        <div className="mx-auto md:mx-0 mt-5 md:mt-0">
          <button className="text-base font-medium py-2 px-8 rounded-full bg-primary hover:bg-primary-hover font-gtmedium" onClick={handlePackage}>Buy the Machine</button>
        </div>
      </div>
    </div>
  )
}

export default Hero;