import React, { useEffect } from 'react';
import { PageContext } from '../../Contexts/PageContext';
import { StaticImage } from 'gatsby-plugin-image';

const ProductBuyBox = () => {
  const { handlePackage } = React.useContext(PageContext);
  const [imgIndex, setImgIndex] = React.useState('0');

  useEffect(() => {
    // let thumbs = document.querySelectorAll("[data-index]");
    // for(let i=0; i<thumbs.length;i++){
    //   thumbs[i].addEventListener("click", function(e) {
    //     setImgIndex(e.target.getAttribute('data-index'))
    //   });
    // }
  }, [])
  
  return(
    <div className="max-w-screen-2xl mx-auto px-5 md:px-16 flex flex-wrap items-center product__buy_box">
      <div className="flex w-full md:w-2/4 mb-8 md:mb-0">
        <div className="mr-5 w-32 hidden md:block">
          <div onClick={() => setImgIndex('0')}>
            <StaticImage
              src="../../images/machine/machine-male.jpg"
              alt="The Machine"
              width={80}
              loading="eager"
              className="block mb-2 cursor-pointer"
              data-index="0"
            />
          </div>
          <div onClick={() => setImgIndex('1')}>
            <StaticImage
              src="../../images/machine/machine-male-2.jpg"
              alt="The Machine"
              width={80}
              loading="eager"
              className="block mb-2 cursor-pointer"
              data-index="1"
            />
          </div>
          <div onClick={() => setImgIndex('2')}>
            <StaticImage
              src="../../images/machine/machine-male-3.jpg"
              alt="The Machine"
              width={80}
              loading="eager"
              className="block mb-2 cursor-pointer"
              data-index="2"
            />
          </div>
          <div onClick={() => setImgIndex('3')}>
            <StaticImage
              src="../../images/machine/machine-male-4.jpg"
              alt="The Machine"
              width={80}
              loading="eager"
              className="block mb-2 cursor-pointer"
              data-index="3"
            />
          </div>
          <div onClick={() => setImgIndex('4')}>
            <StaticImage
              src="../../images/machine/machine-male-5.jpg"
              alt="The Machine"
              width={80}
              loading="eager"
              className="block cursor-pointer"
              data-index="4"
            />
          </div>
        </div>
        <div id="machineimages" className="md:w-63 whitespace-nowrap md:whitespace-normal overflow-x-auto -mx-5 md:mx-0">
          <StaticImage
            src="../../images/machine/machine-male.jpg"
            alt="The Machine"
            width={800}
            loading="eager"
            className={`${imgIndex === "0" ? "md:block": "md:hidden"} w-4/5 md:w-auto`}
          />
          <StaticImage
            src="../../images/machine/machine-male-2.jpg"
            alt="The Machine"
            width={800}
            loading="eager"
            className={`${imgIndex === "1" ? "md:block": "md:hidden"} w-4/5 md:w-auto`}
          />
          <StaticImage
            src="../../images/machine/machine-male-3.jpg"
            alt="The Machine"
            width={800}
            loading="eager"
            className={`${imgIndex === "2" ? "md:block": "md:hidden"} w-4/5 md:w-auto`}
          />
          <StaticImage
            src="../../images/machine/machine-male-4.jpg"
            alt="The Machine"
            width={800}
            loading="eager"
            className={`${imgIndex === "3" ? "md:block": "md:hidden"} w-4/5 md:w-auto`}
          />
          <StaticImage
            src="../../images/machine/machine-male-5.jpg"
            alt="The Machine"
            width={800}
            loading="eager"
            className={`${imgIndex === "4" ? "md:block": "md:hidden"} w-4/5 md:w-auto`}
          />
        </div>        
      </div>
      <div className="w-full md:w-2/4 md:pl-28">
        <h3 className="font-gtbold text-3.5xl md:text-5xl mb-5">The Machine</h3>
        <div className="mb-7">
          From $229.00
          <afterpay-placement 
            data-locale="en_US"
            data-currency="USD" 
            data-amount= "$229"
            style={{ margin: 0}}
          >
          </afterpay-placement>
        </div>
        <button id="buy-box-cta" className="block text-base font-medium py-2 px-8 mx-auto md:m-0 rounded-full bg-primary hover:bg-primary-hover font-gtmedium" onClick={handlePackage}>Shop the Machine</button>
      </div>
    </div>
  )
}

export default ProductBuyBox;