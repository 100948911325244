import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import ScreenGrab from '../../videos/app_screengrab.mp4';
import { PageContext } from '../../Contexts/PageContext';

function AppModule(){
  const { handlePackage } = React.useContext(PageContext);

  return(
    <div className="app__module">
      <div className="bg-app-mobile md:bg-app-desktop md:bg-fixed py-8 px-5 md:p-16 flex flex-wrap items-center bg-contain md:bg-auto bg-no-repeat">
        <div className="w-full md:w-2/4 order-2">
          <h3 className="font-gtbold text-3.5xl md:text-7xl mb-3 md:mb-5 uppercase">FREE AND DISCREET<br/> PROGRAMS</h3>
          <p className="text-sm md:text-base">Our Pelvic Balance Program is a 4-week challenge to help you beat premature ejaculation and erectile dysfunction.  It’s FREE and available at home on our app!</p>
          <div className="italic text-xxs disclaimer pt-4 mb-8 md:mb-14"> 
          A FREE all-access pass for 3 months of premium app content is automatically applied at checkout with the purchase of a machine or machine kits. Valued at $29.97.   
          </div>
          <button id="app-mod-cta" className="block text-base font-medium py-2 px-8 rounded-full bg-primary hover:bg-primary-hover font-gtmedium mx-auto md:mx-0" onClick={handlePackage}>Buy the Machine</button>
        </div>
        <div className="relative app__grab mx-auto md:ml-20 mb-4 md:mb-0 md:order-2"> 
          <video className="absolute" style={{ top: '2%', left: '18%', width: '65%' }} autoPlay muted playsInline loop>
            <source src={ScreenGrab} type="video/mp4" />
          </video>
          <StaticImage
            src="../../images/iphone-skeleton.png"
            alt="Iphone Skeleton"
          />          
        </div>
      </div>
    </div>
  )
}

export default AppModule;