import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { PageContext } from '../../Contexts/PageContext';

function Users(){
  const { handlePackage } = React.useContext(PageContext);

  return(
    <div className="users-proud-module">
      <div className="max-w-screen-xl mx-auto px-5 pt-10 md:px-16 md:pt-14 pb-10 md:pb-0">
        <h3 className="font-gtbold text-3.5xl md:text-5xl mb-5 uppercase text-center">LOSE WEIGHT TOO!</h3>
        <div className="flex items-start yotpo-reviews justify-center">
          <div className="flex">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5"><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5"><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5"><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5"><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5"><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
          </div>
          <span className="text-sm ml-2.5">(2770 Reviews)</span>
        </div>
        <div className="block md:flex whitespace-nowrap md:whitespace-normal overflow-x-auto justify-between mt-9 users_list"> 
          <div className="flex flex-wrap items-center whitespace-normal align-top text-center users_list--item">
            <div className="flex justify-center relative mb-5 w-full md:w-2/4">              
              <StaticImage
                src="../../images/before-after/before-after-1704-3-v3.jpg"
                alt="Users 1"
              />
              <div className="absolute bg-primary rounded-full text-xs py-1 px-2.5 bottom-5 label">Lost 55 LBS</div>
            </div>
            <div className="w-full md:w-2/4 p-0 md:pl-20">
              <p className="mb-4">“Anyone can use this Machine! I use it for not only my legs but for a full body workout.”</p>
              <span>- Jarid G.</span>
            </div>            
          </div>          
        </div>
        <div className="italic text-xxs disclaimer max-w-screen-lg mx-auto pt-6 text-center"> 
          Results may vary depending on starting point, goals, and effort.  Exercise and proper diet are necessary to achieve and maintain weight loss and muscle definition.    
        </div>
        <div className="text-center pt-8 md:pt-16">
          <button className="text-base font-medium py-2 px-8 rounded-full bg-primary hover:bg-primary-hover font-gtmedium" onClick={handlePackage}>Shop the Machine</button>
        </div>
      </div>
    </div>
  )
}

export default Users;